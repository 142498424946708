import { FunctionComponent } from 'react';
import { RadioGroup } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { RiskTag } from 'app/components/Controls/Tags';

const useStyles = tss.withName('FormRadioGroup').create(() => ({
  item: {
    fontSize: '14px',
    lineHeight: 1.7,
    gap: '8px',
    alignItems: 'center'
  },
  itemRisk: {
    '&:has([data-state="unchecked"])': {
      '& span span': {
        backgroundColor: 'var(--slate-200)',
        color: 'var(--font-primary-color)'
      }
    }
  }
}));

export const FormRadioGroup: FunctionComponent<
  RadioGroup.RootProps & {
    items: { label: string; value: string }[];
    labelVariant?: 'default' | 'risk';
  }
> = ({ items, labelVariant = 'default', ...props }) => {
  const { classes } = useStyles();

  return (
    <RadioGroup.Root {...props}>
      {items.map(({ label, value }, i) => (
        <RadioGroup.Item
          key={i}
          value={value}
          className={clsx(classes.item, labelVariant === 'risk' && classes.itemRisk)}
        >
          {labelVariant === 'risk' ? <RiskTag value={label} /> : label}
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
};
