import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import * as Switch from '@radix-ui/react-switch';
import { Flex, Spinner, Text, VisuallyHidden } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { LICENCE_REQUIRED_ROLES } from 'app/constants/rbac';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useLicencedMutation } from 'app/queries/useControlQueries';
import { CustomerControl } from 'app/types/controls';

const useStyles = tss.withName('LicenseField').create(() => ({
  label: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667
  },
  value: {
    color: 'var(--font-primary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    textAlign: 'right'
  },

  switch: {
    width: '42px',
    height: '26px',
    borderRadius: '9999px',
    border: '1px solid var(--slate-200)',
    position: 'relative',
    padding: '0',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  thumb: {
    display: 'block',
    borderRadius: '9999px',
    transition: 'transform 100ms',
    transform: 'translateX(0px)',
    willChange: 'transform',

    '&[data-state="checked"]': {
      transform: 'translateX(16px)'
    }
  }
}));

type LicenseFieldProps = {
  control: CustomerControl;
  onControlChange: (control: CustomerControl) => void;
};

export const LicenseField: FunctionComponent<LicenseFieldProps> = ({
  control,
  onControlChange
}) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [newValue, setNewValue] = useState(control.licenced || false);

  const { mutate, isPending, reset } = useLicencedMutation();

  const userIsAllowed = useMemo(() => isAllowed(LICENCE_REQUIRED_ROLES), [isAllowed]);

  const handleLicenseChange = (licenced: boolean) => {
    setNewValue(licenced);

    mutate(
      { externalControlId: control.externalControlId, licenced },
      {
        onSuccess: response => {
          onControlChange(response);
        },
        onError: () => {
          reset();
          setNewValue(!licenced);
        }
      }
    );
  };

  useEffect(() => setNewValue(control.licenced || false), [control.licenced]);

  return (
    <Flex gap='2' justify='between' align='center'>
      <Text as='p' className={classes.label}>
        Licensed
      </Text>
      {!userIsAllowed && (
        <>
          {control.licenced ? (
            <CheckCircle
              size={24}
              weight='fill'
              color='var(--rag-light-green)'
              data-testid='licenced'
              aria-label='Licensed'
            />
          ) : (
            <XCircle
              size={24}
              weight='fill'
              color='var(--rag-red)'
              data-testid='not-licenced'
              aria-label='Not Licensed'
            />
          )}
        </>
      )}
      {userIsAllowed && (
        <form>
          <Flex align='center'>
            {isPending && <Spinner />}
            <VisuallyHidden asChild>
              <label htmlFor='licenced-toggle'>Toggle license value</label>
            </VisuallyHidden>
            <Switch.Root
              className={classes.switch}
              data-testid='licenced-toggle'
              id='licenced-toggle'
              defaultChecked={newValue}
              checked={newValue}
              onCheckedChange={handleLicenseChange}
            >
              <Switch.Thumb className={classes.thumb} asChild>
                {newValue ? (
                  <CheckCircle
                    size={24}
                    aria-label='Licensed'
                    weight='fill'
                    color='var(--rag-light-green)'
                    data-testid='licenced-toggle-on'
                  />
                ) : (
                  <XCircle
                    size={24}
                    weight='fill'
                    aria-label='Not licensed'
                    color='var(--rag-red)'
                    data-testid='licenced-toggle-off'
                  />
                )}
              </Switch.Thumb>
            </Switch.Root>
          </Flex>
        </form>
      )}
    </Flex>
  );
};
