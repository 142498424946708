import { FunctionComponent } from 'react';
import { Box, Flex } from '@radix-ui/themes';
import { format } from 'date-fns';

import { SolutionIcon } from 'app/components/Controls';
import { CustomerControl, NISPrinciple } from 'app/types/controls';

import { LicenseField } from './LicenseField';
import { SidePanelDetailsField } from './SidePanelDetailsField';
import { SidePanelSection } from './SidePanelSection';

type DetailsSectionProps = {
  control: CustomerControl;
  onControlChange: (control: CustomerControl) => void;
};

const formatNISValue = (nisPrinciples?: NISPrinciple[]) => {
  if (!nisPrinciples || !nisPrinciples.length) return;
  return (
    <>
      {nisPrinciples.map(({ id, name }) => (
        <span key={`nis-${id}`}>
          {name}
          <br />
        </span>
      ))}
    </>
  );
};

export const DetailsSection: FunctionComponent<DetailsSectionProps> = ({
  control,
  onControlChange
}) => {
  return (
    <SidePanelSection label='Details'>
      <Box>
        <Flex gap='2' direction={'column'}>
          <SidePanelDetailsField
            label='Last updated'
            type='text'
            value={control.lastUpdatedAt ? format(control.lastUpdatedAt, 'd MMM yyyy') : ''}
          />
          <LicenseField control={control} onControlChange={onControlChange} />
          <SidePanelDetailsField label='Control version' type='text' value={control.version} />
          <SidePanelDetailsField
            label='Solution'
            type='text'
            value={
              control.solution && (
                <Flex as='span' align='center' gap='8px'>
                  {control.solution?.name}
                  <SolutionIcon solution={control.solution} />
                </Flex>
              )
            }
          />
          <SidePanelDetailsField
            label='NIS principles'
            type='text'
            value={formatNISValue(control.nisPrinciples)}
          />
        </Flex>
      </Box>
    </SidePanelSection>
  );
};
