import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { axiosInstance } from 'app/constants/axios';
import { MASTER_CONTROLS_API_URLS } from 'app/constants/routes';
import {
  ApiError,
  Control,
  CreateMasterControlRequest,
  MasterControl,
  PagedResponse,
  UpdateMasterControlRequest
} from 'app/types';

type UseFetchMasterControlsOptions = {
  page?: number;
  size?: number;
  sort?: string;
  freeText?: string;
  enabled?: boolean;
};

export const USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY = 'useFetchMasterControlsList';

export const useFetchMasterControlsList = ({
  page = 0,
  size = 20,
  sort = 'name,desc',
  freeText = '',
  enabled = true
}: UseFetchMasterControlsOptions) => {
  return useQuery({
    queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY, { page, size, sort, freeText }],
    queryFn: async () => {
      const response = await axiosInstance.get<PagedResponse<Control>>(
        MASTER_CONTROLS_API_URLS.ROOT,
        {
          params: { page, size, sort, freeText }
        }
      );
      return response.data;
    },
    enabled
  });
};

export const useDeleteMasterControlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useDeleteMasterControlMutation'],
    mutationFn: async (externalControlId: number) =>
      await axiosInstance
        .delete(MASTER_CONTROLS_API_URLS.SINGLE.replace(':id', `${externalControlId}`))
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY] });
    }
  });
};

export interface CreateMasterControlOptions {
  control: CreateMasterControlRequest;
}

export const useCreateMasterControlMutation = () =>
  useMutation<Control, Error | ApiError, CreateMasterControlOptions>({
    mutationKey: ['useCreateMasterControlMutation'],
    mutationFn: async ({ control }: CreateMasterControlOptions) =>
      await axiosInstance
        .post<Control>(MASTER_CONTROLS_API_URLS.ROOT, {
          ...control,
          name: control.name.trim(),
          controlNotes: control.controlNotes.trim(),
          version: control.version.trim()
        })
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        })
  });

const USE_FETCH_MASTER_CONTROL_QUERY_KEY = 'useFetchMasterControl';
export const useFetchMasterControl = (enabled = false, id: string) =>
  useQuery({
    queryKey: [USE_FETCH_MASTER_CONTROL_QUERY_KEY, id],

    queryFn: async () =>
      await axiosInstance
        .get<MasterControl>(MASTER_CONTROLS_API_URLS.SINGLE.replace(':id', id))
        .then(response => response.data),
    enabled
  });

export interface UpdateMasterControlMutationOptions {
  id: number;
  control: UpdateMasterControlRequest;
}
export const useUpdateMasterControlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<MasterControl, Error | ApiError, UpdateMasterControlMutationOptions>({
    mutationKey: ['useUpdateMasterControlMutation'],
    mutationFn: async ({ id, control }: UpdateMasterControlMutationOptions) =>
      await axiosInstance
        .put<MasterControl>(MASTER_CONTROLS_API_URLS.SINGLE.replace(':id', `${id}`), {
          ...control,
          name: control.name.trim(),
          controlNotes: control.controlNotes.trim(),
          version: control.version.trim()
        })
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY, USE_FETCH_MASTER_CONTROL_QUERY_KEY]
      })
  });
};
