import { FunctionComponent, useState } from 'react';
import { Checkbox, Flex, Text, TextArea } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PrimaryButton, SecondaryButton } from 'app/components/Buttons';
import { useDeleteMasterControlMutation } from 'app/queries/useMasterControlsQueries';
import { useCreateWhatsNewBulletinMutation } from 'app/queries/useWhatsNewQueries';

const useStyles = tss.withName('MasterControlDelete').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--red-a5)',
    background: 'var(--red-a4)',
    display: 'flex',
    padding: '8px'
  },
  text: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.71
  },
  button: {
    borderRadius: '6px',
    background: 'var(--red-a5)'
  },
  textarea: {
    flex: 1,
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--bg-inputs)',
    boxShadow: 'none'
  },
  publishBulletinText: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1
  }
}));

type DeleteTableRowProps = {
  externalControlId: number;
  setDeleteRow: React.Dispatch<React.SetStateAction<null>>;
};

export const MasterControlDelete: FunctionComponent<DeleteTableRowProps> = ({
  setDeleteRow,
  externalControlId
}) => {
  const { classes } = useStyles();
  const [publishBulletinChecked, setPublishBulletinChecked] = useState(false);
  const [deleteControlBulletinText, setDeleteControlBulletinText] = useState('Control deleted');
  const { mutate: deleteMasterControl } = useDeleteMasterControlMutation();
  const { mutate: createNewBulletin, isPending } = useCreateWhatsNewBulletinMutation();

  const handleDelete = () => {
    deleteMasterControl(externalControlId, {
      onSuccess: () => {
        publishBulletinChecked &&
          !isPending &&
          createNewBulletin({
            externalControlId: externalControlId,
            content: deleteControlBulletinText
          });
        setDeleteRow(null);
      }
    });
  };

  return (
    <div className={classes.container}>
      <Flex gap='4' align='center' justify='between' flexGrow='1'>
        <Flex direction='column' flexGrow='1'>
          <Text className={classes.text}>
            Control will be permanently deleted. This action cannot be undone.
          </Text>

          <Flex align='start' gap='2' data-testid='publish-bulletin-section'>
            <Checkbox
              onClick={() => setPublishBulletinChecked(!publishBulletinChecked)}
              data-testid='publish-bulletin-checkbox'
            />
            <Text className={classes.publishBulletinText} weight='bold'>
              Publish bulletin:
            </Text>
            {publishBulletinChecked ? (
              <TextArea
                className={classes.textarea}
                resize='vertical'
                color='gray'
                autoFocus
                value={deleteControlBulletinText}
                onChange={e => setDeleteControlBulletinText(e.target.value)}
                data-testid='publish-bulletin-textarea'
              />
            ) : (
              <Text className={classes.publishBulletinText}>{deleteControlBulletinText}</Text>
            )}
          </Flex>
        </Flex>
        <Flex gap='4'>
          <SecondaryButton
            onClick={() => setDeleteRow(null)}
            data-testid='delete-cancel-button'
            title='Cancel'
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className={classes.button}
            onClick={() => handleDelete()}
            data-testid='delete-confirm-button'
            title='Delete Control'
          >
            Delete Control
          </PrimaryButton>
        </Flex>
      </Flex>
    </div>
  );
};
