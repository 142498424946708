import { FunctionComponent, useMemo } from 'react';

import { Solution } from 'app/types';
import { ReactComponent as CopilotIcon } from 'assets/solution-icons/copilot.svg';
import { ReactComponent as DefenderIcon } from 'assets/solution-icons/defender.svg';
import { ReactComponent as EntraIcon } from 'assets/solution-icons/entra.svg';
import { ReactComponent as FormsIcon } from 'assets/solution-icons/forms.svg';
import { ReactComponent as GenericIcon } from 'assets/solution-icons/generic.svg';
import { ReactComponent as IntuneIcon } from 'assets/solution-icons/intune.svg';
import { ReactComponent as PurviewIcon } from 'assets/solution-icons/purview.svg';
import { ReactComponent as SharePointIcon } from 'assets/solution-icons/sharepoint.svg';
import { ReactComponent as SwayIcon } from 'assets/solution-icons/sway.svg';
import { ReactComponent as TeamsIcon } from 'assets/solution-icons/teams.svg';
import { ReactComponent as WindowsIcon } from 'assets/solution-icons/windows.svg';

type SolutionIconProps = {
  solution: Solution;
  className?: string;
  size?: number;
};

export const SolutionIcon: FunctionComponent<SolutionIconProps> = ({
  solution,
  className,
  size = 20
}) => {
  const SVGComponent = useMemo(() => {
    switch (solution.name.toLocaleLowerCase()) {
      case 'copilot for microsoft 365':
        return CopilotIcon;
      case 'defender for endpoint':
      case 'defender vulnerability management':
      case 'defender for office 365 + exchange online protection':
      case 'defender for identity':
      case 'defender for cloud apps':
        return DefenderIcon;
      case 'entra id conditional access':
      case 'entra id governance and management':
      case 'entra id hybrid identity':
      case 'entra id protection':
      case 'entra id authentication methods and reset':
      case 'entra id apps and workload identities':
      case 'entra id external identities':
      case 'entra id monitoring and health':
        return EntraIcon;
      case 'forms':
        return FormsIcon;
      case 'intune':
        return IntuneIcon;
      case 'purview':
        return PurviewIcon;
      case 'sharepoint online':
        return SharePointIcon;
      case 'sway':
        return SwayIcon;
      case 'teams':
        return TeamsIcon;
      case 'windows':
      case 'windows hello for business':
        return WindowsIcon;
      default:
        return GenericIcon;
    }
  }, [solution]);

  return (
    <>
      {SVGComponent && (
        <SVGComponent aria-hidden={true} className={className} width={size} height={size} />
      )}
    </>
  );
};
