import { FunctionComponent } from 'react';
import * as FormPrimitive from '@radix-ui/react-form';
import { TextArea } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormTextArea').create(() => ({
  control: {
    boxShadow: 'inset 0 0 0 1px var(--slate-200)',
    backgroundColor: 'var(--bg-inputs)',
    width: '100%',
    '&>textarea': {
      padding: '8px 12px',
      textIndent: 'initial',
      '&[data-invalid="true"]': {
        boxShadow: 'inset 0 0 0 1px var(--rag-red)',
        backgroundColor: 'var(--red-a3)'
      },
      '&[readonly]': {
        color: 'var(--font-primary-color)',
        WebkitTextFillColor: 'var(--font-primary-color)',
        pointerEvents: 'none'
      }
    }
  }
}));

type FormTextAreaProps = FormPrimitive.FormControlProps & {
  rows?: number;
};

export const FormTextArea: FunctionComponent<FormTextAreaProps> = ({ rows = 4, ...props }) => {
  const { classes } = useStyles();

  return (
    <FormPrimitive.Control asChild type='text' {...props} className={classes.control}>
      <TextArea rows={rows} />
    </FormPrimitive.Control>
  );
};
