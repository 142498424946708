export const WHATS_NEW_BULLETIN_DEFAULT_TEXT = 'New control created';
export const WHATS_NEW_STORED_MASTER_CONTROL_INFO = 'storedMasterControlInfo';

export const WHATS_NEW_BULLETINS_KEYS_MAP = [
  { key: 'externalControlId', label: 'Control ID', detailedInfo: true },
  { key: 'name', label: 'Name', detailedInfo: true },
  { key: 'controlNotes', label: 'Notes', detailedInfo: false },
  { key: 'version', label: 'Version number', detailedInfo: true },
  { key: 'm365Licences', label: 'M365 license', detailedInfo: true },
  { key: 'securityRisk', label: 'Risk', detailedInfo: true },
  { key: 'businessRisk', label: 'Impact', detailedInfo: true },
  { key: 'nisPrincipleIds', label: 'NIS principles', detailedInfo: false },
  { key: 'solution', label: 'Solution', detailedInfo: true }
];
