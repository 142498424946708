import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Button, Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FilterSection').create(() => ({
  button: {
    cursor: 'pointer',
    padding: '0',
    margin: '0',
    background: 'none',
    width: '100%'
  },
  label: {
    cursor: 'pointer',
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'left'
  }
}));

export type FilterSectionProps = PropsWithChildren & {
  label: string;
  labelId: string;
  defaultAsOpen?: boolean;
  disableCollapse?: boolean;
};

export const FilterSection: FunctionComponent<FilterSectionProps> = ({
  label,
  labelId,
  defaultAsOpen = true,
  disableCollapse = false,
  children
}) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(defaultAsOpen);

  return (
    <Collapsible.Root asChild open={open} onOpenChange={setOpen} disabled={disableCollapse}>
      <Flex direction='column' gap='2' flexGrow='1'>
        <Collapsible.Trigger asChild>
          <Button variant='ghost' className={classes.button} data-testid={labelId}>
            <Flex gap='2' flexGrow='1' align='center'>
              <label id={labelId} className={classes.label}>
                {label}
              </label>
              {disableCollapse ? undefined : open ? <CaretUp size={16} /> : <CaretDown size={16} />}
            </Flex>
          </Button>
        </Collapsible.Trigger>
        <Collapsible.Content>{children}</Collapsible.Content>
      </Flex>
    </Collapsible.Root>
  );
};
