import { forwardRef } from 'react';
import { Card, Flex, Heading, Text } from '@radix-ui/themes';
import { clsx } from 'clsx';
import { tss } from 'tss-react';

import { CONTROL_STATUS } from 'app/constants/controls';
import { CustomerControl } from 'app/types/controls';

import { ControlStatusIcon } from './ControlStatusIcon';
import { BusinessImpactTag, LicenseTag, SecurityRiskTag, SolutionTag } from './Tags';

const useStyles = tss.withName('CustomerControlCard').create(() => ({
  container: {
    background: 'var(--white)',
    cursor: 'pointer',
    padding: '16px',
    overflow: 'initial',
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)',

    '&:before': {
      backgroundColor: 'transparent'
    },

    '&:hover, &:focus': {
      background: 'var(--bg-default)',
      boxShadow: 'none'
    }
  },
  containerActive: {
    background: 'var(--bg-default)',
    boxShadow: 'none',
    borderColor: 'var(--green-a10)',
    '&:after': {
      boxShadow: 'none'
    }
  },
  heading: {
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 400
  },
  controlNote: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  id: {
    fontSize: '12px',
    color: 'var(--font-secondary-color)'
  }
}));

type CustomerControlCardProps = {
  control: CustomerControl;
  isActiveCard: boolean;
  onClick?: (control: CustomerControl) => void;
  ref?: React.Ref<HTMLDivElement>;
};

export const CustomerControlCard = forwardRef<HTMLDivElement, CustomerControlCardProps>(
  ({ control, isActiveCard, onClick }, ref) => {
    const { classes } = useStyles();

    return (
      <Card
        className={clsx(classes.container, isActiveCard && classes.containerActive)}
        data-testid='customer-control-card'
        role='listitem'
        asChild
        ref={ref}
        tabIndex={0}
      >
        <button onClick={() => onClick?.(control)} tabIndex={0}>
          <Flex direction='column' gap='2'>
            <Flex direction='column'>
              <Flex justify='between' align='center'>
                <Text as='p' className={classes.id}>
                  #{control.externalControlId}
                </Text>
                <ControlStatusIcon size={24} status={control.status as CONTROL_STATUS} />
              </Flex>
              <Heading as='h3' className={classes.heading}>
                {control.name}
              </Heading>
            </Flex>
            <Text as='p' className={classes.controlNote}>
              {control.controlNotes}
            </Text>
            <Flex gap='2' align='center' wrap={'wrap'}>
              {control.securityRisk && <SecurityRiskTag value={control.securityRisk} />}
              {control.businessRisk && <BusinessImpactTag value={control.businessRisk} />}
              {control.solution && <SolutionTag solution={control.solution} />}
              <LicenseTag licenced={!!control.licenced} />
            </Flex>
          </Flex>
        </button>
      </Card>
    );
  }
);
