import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';
import { Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { ConnectionError, FreeTextFilter, PrimaryButton } from 'app/components';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers';
import { Pagination } from 'app/components/Tables';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { SortBy, SortOrder } from 'app/constants/customers';
import { CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES } from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useFetchCustomerList } from 'app/queries/useCustomerManagementQueries';

import { CustomersTable } from './CustomersTable/CustomersTable';

const useStyles = tss.withName('Customers').create(() => ({
  container: {
    gap: '16px'
  }
}));
export const Customers: FunctionComponent = () => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [freeText, setFreeText] = useState('');
  const [sortState, setSortState] = useState({
    sortBy: (searchParams.get('sort')?.split(',')[0] as SortBy) || 'name',
    sortOrder: (searchParams.get('sort')?.split(',')[1] as SortOrder) || 'desc'
  });

  const { data, isFetching, isLoading, isError, refetch } = useFetchCustomerList({
    enabled: true,
    page: currentPage,
    size: pageSize,
    sort: `${sortState.sortBy},${sortState.sortOrder}`,
    freeText
  });

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleFreeTextChange = (text: string) => {
    setFreeText(text);
    setCurrentPage(0);
  };

  const hasCreateAccess = useMemo(
    () => isAllowed(CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES),
    [isAllowed]
  );

  useEffect(() => {
    setSearchParams(prevParams => {
      prevParams.set('sort', `${sortState.sortBy},${sortState.sortOrder}`);
      return prevParams;
    });
  }, [setSearchParams, sortState]);

  // While loading, data?.page is undefined, so need to store in state
  const [pageObject, setPageObject] = useState(
    data?.page || { totalPages: 0, totalElements: 0, size: 0, number: 0 }
  );
  useEffect(
    () => setPageObject(prevRowCount => (data?.page !== undefined ? data?.page : prevRowCount)),
    [data, data?.page, setPageObject]
  );

  return (
    <AdminCentrePageWrapper
      section={ADMIN_SECTIONS.CUSTOMERS}
      toolbar={
        <Flex gap='4' wrap='wrap' flexGrow='1' justify='between'>
          {hasCreateAccess ? (
            <Link to={`/${URLS.ADMIN_CENTRE_CUSTOMERS_CREATE}`}>
              <PrimaryButton>
                <Plus size={16} />
                Add Customer
              </PrimaryButton>
            </Link>
          ) : (
            <div />
          )}

          <FreeTextFilter defaultValue={freeText} onChange={handleFreeTextChange} />
        </Flex>
      }
    >
      <Flex direction='column' className={classes.container}>
        {isError && <ConnectionError />}
        <CustomersTable
          data={data?.content || []}
          pageSize={pageSize}
          sortState={sortState}
          setSortState={setSortState}
          isLoading={isFetching || isLoading}
          refetch={refetch}
        />
        <Pagination
          currentPage={currentPage}
          pageObject={pageObject}
          pageSize={pageSize}
          perPageItems={(data?.content.length || 0) + pageSize * currentPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          isDisabled={isFetching || isLoading}
        />
      </Flex>
    </AdminCentrePageWrapper>
  );
};
