import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { LOCALSTORAGE_CUSTOMER_ID_KEY } from 'app/constants/app';
import { useFetchCustomers } from 'app/queries/useCustomerQueries';
import { ApiError } from 'app/types/ApiError';
import { SimpleCustomer } from 'app/types/customers';

import { useLoggedInUser } from './useLoggedInUser';

type SelectedCustomerContextType = {
  isLoading: boolean;
  error?: Error | ApiError | null;
  customers?: SimpleCustomer[];
  selectedCustomer?: SimpleCustomer;
  setSelectedCustomer: (customer: SimpleCustomer) => void;
  refetchCustomers: () => void;
};

const SelectedCustomerContext = createContext<SelectedCustomerContextType>({
  isLoading: true
} as SelectedCustomerContextType);

export const SelectedCustomerProvider = (props: PropsWithChildren) => {
  const { isLoading: isLoggedInUserLoading, isThreatscapeUser } = useLoggedInUser();

  const [selectedCustomer, setSelectedCustomer] = useState<SimpleCustomer>();

  const { isLoading, data, error, refetch } = useFetchCustomers(
    !isLoggedInUserLoading && isThreatscapeUser
  );

  useEffect(() => {
    if (!isLoading && !isLoggedInUserLoading && data && data.length > 0) {
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      let defaultCustomer = sortedData.find(val => val.enabled) ?? sortedData[0];

      try {
        const localVal = localStorage.getItem(LOCALSTORAGE_CUSTOMER_ID_KEY);
        if (localVal) {
          const found = sortedData.find(val => val.id === Number(localVal));
          if (found) {
            defaultCustomer = found;
          }
        }
      } finally {
        setSelectedCustomer(defaultCustomer);
      }
    }
  }, [data, isLoading, isLoggedInUserLoading]);

  return (
    <SelectedCustomerContext.Provider
      value={{
        isLoading: isLoading || isLoggedInUserLoading,
        error,
        customers: data,
        selectedCustomer,
        setSelectedCustomer: (customer: SimpleCustomer) => {
          setSelectedCustomer(customer);
          localStorage.setItem(LOCALSTORAGE_CUSTOMER_ID_KEY, `${customer.id}`);
        },
        refetchCustomers: refetch
      }}
    >
      {props.children}
    </SelectedCustomerContext.Provider>
  );
};

export const useSelectedCustomer = () => useContext(SelectedCustomerContext);
