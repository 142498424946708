import { FunctionComponent } from 'react';
import { CheckboxGroup, Flex } from '@radix-ui/themes';

import { CONTROL_STATUS, CONTROL_STATUS_KEYS, CONTROL_STATUS_LABELS } from 'app/constants/controls';
import { useCommonFilterStyles } from 'app/constants/filters';
import { CommonFilterProps } from 'app/types/filters';

import { ControlStatusIcon } from '../Controls';

import { CheckboxFilter } from './common/CheckboxFilter';

const STATUS_KEYS = Object.keys(CONTROL_STATUS) as CONTROL_STATUS_KEYS[];

export const StatusFilter: FunctionComponent<CommonFilterProps> = props => {
  const { classes } = useCommonFilterStyles();

  return (
    <CheckboxFilter
      label='Status'
      labelId='status-filter'
      {...props}
      options={STATUS_KEYS.map(val => (
        <CheckboxGroup.Item
          key={val}
          value={val}
          className={classes.checkboxItem}
          data-testid={`status-filter-item-${val}`}
        >
          <Flex gap='2' align='center'>
            <ControlStatusIcon size={24} status={CONTROL_STATUS[val]} />
            {CONTROL_STATUS_LABELS[val]}
          </Flex>
        </CheckboxGroup.Item>
      ))}
    />
  );
};
