import _ from 'lodash';

import { LICENCES_LABELS } from 'app/constants/customers';
import { WHATS_NEW_BULLETINS_KEYS_MAP } from 'app/constants/whatsNew';
import { Solution, UpdateMasterControlRequest } from 'app/types';

export type BulletinData = Omit<UpdateMasterControlRequest, 'solutionId'> & { solution: string };

export const findControlDifferences = (
  newControlData: BulletinData,
  oldControlData: BulletinData
) => {
  const controlFormKeys = Object.keys(newControlData).filter(
    (key): key is keyof BulletinData => key !== 'solutionId'
  );

  const differencesList = controlFormKeys.reduce(
    (
      acc: Record<
        string,
        {
          newData: BulletinData[keyof BulletinData];
          oldData: BulletinData[keyof BulletinData];
        }
      >,
      key
    ) => {
      if (!_.isEqual(newControlData[key], oldControlData[key])) {
        acc[key] = { newData: newControlData[key], oldData: oldControlData[key] };
      }
      return acc;
    },
    {}
  );

  return Object.keys(differencesList)
    .map(key =>
      WHATS_NEW_BULLETINS_KEYS_MAP[WHATS_NEW_BULLETINS_KEYS_MAP.findIndex(item => item.key === key)]
        .detailedInfo
        ? `${WHATS_NEW_BULLETINS_KEYS_MAP.find(item => item.key === key)?.label} updated from ${differencesList[key].oldData} to ${differencesList[key].newData}`
        : `${WHATS_NEW_BULLETINS_KEYS_MAP.find(item => item.key === key)?.label} updated`
    )
    .join('\n');
};

export const capitalizeText = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const storedOldAndNewControlData = (
  oldControlData: UpdateMasterControlRequest,
  newControlData: UpdateMasterControlRequest,
  solutionsData: Solution[]
) =>
  JSON.stringify({
    oldControlData: {
      ...oldControlData,
      solution: solutionsData?.find(val => val.id === Number(oldControlData?.solutionId))?.name,
      m365Licences: oldControlData?.m365Licences
        .map(licence => LICENCES_LABELS[licence as keyof typeof LICENCES_LABELS])
        .join(', '),
      securityRisk: capitalizeText(oldControlData!.securityRisk),
      businessRisk: capitalizeText(oldControlData!.businessRisk)
    },
    newControlData: {
      ...newControlData,
      solution: solutionsData?.find(val => val.id === Number(newControlData.solutionId))?.name,
      m365Licences: newControlData.m365Licences
        .map(licence => LICENCES_LABELS[licence as keyof typeof LICENCES_LABELS])
        .join(', '),
      securityRisk: capitalizeText(newControlData.securityRisk),
      businessRisk: capitalizeText(newControlData.businessRisk)
    },
    action: 'edit',
    externalControlId: Number(newControlData?.externalControlId)
  });
