import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trash, Warning } from '@phosphor-icons/react';
import { Button, Callout, Flex, IconButton, Spinner, Text, TextArea } from '@radix-ui/themes';
import clsx from 'clsx';
import { format } from 'date-fns';
import { tss } from 'tss-react';

import { WHATS_NEW_MODIFY_DELETE_REQUIRED_ROLES } from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import {
  useDeleteWhatsNewBulletinMutation,
  useUpdateWhatsNewBulletinMutation
} from 'app/queries/useWhatsNewQueries';
import { WhatsNewBulletin } from 'app/types';

import { PrimaryButton, SecondaryButton } from '../Buttons';

const useStyles = tss.withName('WhatsNewItem').create(() => ({
  itemText: {
    color: 'var(--lime-a7)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.43
  },
  itemTitle: {
    width: '100%',
    textAlign: 'left'
  },
  itemDescription: {
    paddingTop: '4px',
    paddingLeft: '8px',
    color: 'var(--lime-a7)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.43,
    width: '100%',
    textAlign: 'left'
  },

  showOnHover: {
    opacity: 0,
    '&:hover, &:focus': {
      opacity: 1
    }
  },
  deleteIconButton: {
    color: 'var(--red-a5)',
    cursor: 'pointer',
    '--icon-button-ghost-padding': 0
  },
  deleteButton: {
    background: 'var(--red-a5) !important',
    color: 'var(--white)'
  },
  deleteBulletin: {
    borderRadius: '6px',
    padding: '8px',
    border: '1px solid var(--red-a5)',
    background: 'var(--red-a4)',
    color: 'var(--slate-700)',
    fontSize: '14px',
    lineHeight: 1.71
  },
  container: {
    cursor: 'pointer',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    '&:hover, &:focus-within': {
      backgroundColor: 'var(--lime-a2)'
    }
  },
  editMode: {
    borderRadius: '6px',
    padding: '8px',
    border: '1px solid var(--lime-a7)',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  textarea: {
    boxShadow: 'inset 0 0 0 var(--text-area-border-width) var(--slate-200)',
    backgroundColor: 'var(--bg-inputs)',
    marginTop: '4px',
    '&>textarea': {
      padding: '8px 12px'
    }
  },
  submit: {
    ':disabled': {
      color: 'var(--white)',
      opacity: 0.3
    }
  }
}));

type WhatsNewItemProps = {
  whatsNewItem: WhatsNewBulletin;
};

export const WhatsNewItem: FunctionComponent<WhatsNewItemProps> = ({ whatsNewItem }) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();
  const [deleteBulletin, setDeleteBulletin] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newValue, setNewValue] = useState(whatsNewItem.content);

  const {
    mutate: deleteBulletinMutation,
    isPending: isDeleteBulletinMutationPending,
    isError: isDeleteBulletinMutationError,
	reset: resetDeleteBulletinMutation
  } = useDeleteWhatsNewBulletinMutation();

  const {
    mutate: updateBulletinMutation,
    isPending: isUpdateBulletinMutationPending,
    isError: isUpdateBulletinMutationError,
    reset: resetUpdateBulletinMutation
  } = useUpdateWhatsNewBulletinMutation();

  const userIsAllowed = useMemo(
    () => isAllowed(WHATS_NEW_MODIFY_DELETE_REQUIRED_ROLES),
    [isAllowed]
  );

  const handleClickEditMode = () => {
    if (userIsAllowed) {
      setEditMode(true);
    }
  };

  const handleBulletinDelete = () => {
    deleteBulletinMutation(whatsNewItem.id);
  };

  const handleCancelDeletionMode = () => {
	setDeleteBulletin(false);
	resetDeleteBulletinMutation();
  };

  const handleCancel = () => {
    resetUpdateBulletinMutation();
    setNewValue(whatsNewItem.content);
    setEditMode(false);
  };

  const handleBulletinUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateBulletinMutation(
      { id: whatsNewItem.id, content: newValue },
      {
        onSuccess: response => {
          whatsNewItem.content = response.content;
          setEditMode(false);
        }
      }
    );
  };

  return (
    <Flex
      justify='between'
      direction='column'
      gap='1'
      data-testid={`whats-new-item-${whatsNewItem.id}`}
      className={clsx(editMode && !deleteBulletin && classes.editMode)}
    >
      {deleteBulletin ? (
        <div
          className={classes.deleteBulletin}
          data-testid={`delete-bulletin-container-${whatsNewItem.id}`}
        >
          <Flex direction='column' gap='4'>
            <Text as='p'>Bulletin will be permanently deleted.</Text>
            {isDeleteBulletinMutationError && (
              <Callout.Root color='red' size='1' data-testid='bulletin-delete-error'>
                <Callout.Icon>
                  <Warning />
                </Callout.Icon>
                <Callout.Text>Unable to delete bulletin.</Callout.Text>
              </Callout.Root>
            )}
            <Flex direction='row' gap='4' align='center' justify='end'>
              {isDeleteBulletinMutationPending && <Spinner />}

              <SecondaryButton
                type='reset'
                onClick={() => handleCancelDeletionMode()}
                disabled={isDeleteBulletinMutationPending}
                data-testid={`delete-bulletin-cancel-btn-${whatsNewItem.id}`}
				aria-label='Cancel deletion and go back to edit mode'
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                asChild
                disabled={isDeleteBulletinMutationPending}
                data-testid={`delete-bulletin-submit-btn-${whatsNewItem.id}`}
                className={classes.deleteButton}
                onClick={() => handleBulletinDelete()}
				aria-label='Delete bulletin'
              >
                <PrimaryButton className={classes.deleteButton}>Delete</PrimaryButton>
              </PrimaryButton>
            </Flex>
          </Flex>
        </div>
      ) : (
        <>
          <Flex gap='2' justify='between' width='100%'>
            <Link
              className={classes.itemText}
              to={`/${URLS.CONTROLS}?controlId=${whatsNewItem.masterControl.externalControlId}`}
            >
              #{whatsNewItem.masterControl.externalControlId}
            </Link>
            <Text className={classes.itemText}>{format(whatsNewItem.createdAt, 'HH:mm')}</Text>
          </Flex>
          {!editMode && (
            <Button
              variant='ghost'
              onClick={() => handleClickEditMode()}
              data-testid={`whats-new-item-container-${whatsNewItem.id}`}
              className={classes.container}
              title='Click to enter edit mode'
			  aria-label='Click to enter edit mode'
            >
              <Text as='p' className={clsx(classes.itemText, classes.itemTitle)}>
                {whatsNewItem.masterControl.name}
              </Text>
              <Text as='p' className={classes.itemDescription}>
                {whatsNewItem.content.split('\n').map((line, index) => (
                  <Fragment key={index}>
                    {line}
                    <br />
                  </Fragment>
                ))}
              </Text>
            </Button>
          )}
          {editMode && userIsAllowed && (
            <div data-testid={`whats-new-item-edit-mode-${whatsNewItem.id}`}>
              <Text as='p' className={classes.itemText}>
                {whatsNewItem.masterControl.name}
              </Text>
              <form onSubmit={handleBulletinUpdate}>
                <Flex direction='column' gap='2'>
                  <TextArea
                    className={classes.textarea}
                    resize='vertical'
                    color='gray'
                    autoFocus
                    placeholder='No bulletin text.'
                    disabled={isUpdateBulletinMutationPending}
                    value={newValue}
                    onChange={e => setNewValue(e.target.value)}
                    data-testid={`bulletin-content-input-${whatsNewItem.id}`}
					aria-label='Bulletin content'
                  />
                  {isUpdateBulletinMutationError && (
                    <Callout.Root color='red' size='1' data-testid='bulletin-update-error'>
                      <Callout.Icon>
                        <Warning />
                      </Callout.Icon>
                      <Callout.Text>Unable to save data.</Callout.Text>
                    </Callout.Root>
                  )}
                  <Flex gap='2' justify='between' align='center'>
                    <IconButton
                      className={classes.deleteIconButton}
                      variant='ghost'
                      color='gray'
                      data-testid={`delete-bulletin-btn-${whatsNewItem.id}`}
                      onClick={() => setDeleteBulletin(true)}
                      type='button'
                      title='Delete bulletin'
					  aria-label='Go to delete bulletin mode'
                    >
                      <Trash size={20} weight='fill' />
                    </IconButton>

                    <Flex gap='2' direction='row' justify='end' align='center'>
                      {isUpdateBulletinMutationPending && <Spinner />}
                      <SecondaryButton
                        type='reset'
                        onClick={() => handleCancel()}
                        disabled={isUpdateBulletinMutationPending}
                        data-testid={`cancel-edit-mode-btn-${whatsNewItem.id}`}
						aria-label='Cancel edit mode'
						title='Cancel'
                      >
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        type='submit'
                        disabled={isUpdateBulletinMutationPending || newValue.length === 0}
                        data-testid={`edit-mode-submit-btn-${whatsNewItem.id}`}
                        className={classes.submit}
						aria-label='Save changes'
						title='Save changes'
                      >
                        Save
                      </PrimaryButton>
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            </div>
          )}
        </>
      )}
    </Flex>
  );
};
