import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { axiosInstance } from 'app/constants/axios';
import { CUSTOMERS_API_URLS } from 'app/constants/routes';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  ApiError,
  CreateCustomerRequest,
  Customer,
  PagedResponse,
  UpdateCustomerRequest
} from 'app/types';

import { USE_FETCH_ALL_CUSTOMERS_QUERY_KEY } from './useCustomerQueries';

type UseFetchCustomersOptions = {
  freeText?: string;
  page?: number;
  size?: number;
  sort?: string;
  enabled?: boolean;
};

export const USE_FETCH_CUSTOMER_LIST_QUERY_KEY = 'useFetchCustomerList';
export const useFetchCustomerList = ({
  freeText = '',
  page = 0,
  size = 20,
  sort = 'name,desc',
  enabled = true
}: UseFetchCustomersOptions) => {
  return useQuery({
    queryKey: [USE_FETCH_CUSTOMER_LIST_QUERY_KEY, { page, size, sort, freeText }],
    queryFn: async () => {
      const response = await axiosInstance.get<PagedResponse<Customer>>(CUSTOMERS_API_URLS.ROOT, {
        params: { page, size, sort, freeText }
      });
      return response.data;
    },
    enabled
  });
};

const USE_FETCH_CUSTOMER_QUERY_KEY = 'useFetchCustomer';
export const useFetchCustomer = (enabled = false, id: string) =>
  useQuery({
    queryKey: [USE_FETCH_CUSTOMER_QUERY_KEY, id],
    queryFn: async () =>
      await axiosInstance
        .get<Customer>(CUSTOMERS_API_URLS.SINGLE.replace(':id', id))
        .then(response => response.data),
    enabled
  });

export interface CreateCustomerMutationOptions {
  customer: CreateCustomerRequest;
}
export const useCreateCustomerMutation = () => {
  const queryClient = useQueryClient();
  const { refetchCustomers } = useSelectedCustomer();
  return useMutation<Customer, Error | ApiError, CreateCustomerMutationOptions>({
    mutationKey: ['useCreateCustomerMutation'],
    mutationFn: async ({ customer }: CreateCustomerMutationOptions) =>
      await axiosInstance
        .post<Customer>(CUSTOMERS_API_URLS.ROOT, { ...customer, name: customer.name.trim() })
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_CUSTOMER_LIST_QUERY_KEY, USE_FETCH_ALL_CUSTOMERS_QUERY_KEY]
      });
      refetchCustomers();
    }
  });
};

export interface UpdateCustomerMutationOptions {
  id: number;
  customer: UpdateCustomerRequest;
}
export const useUpdateCustomerMutation = () => {
  const queryClient = useQueryClient();
  const { refetchCustomers } = useSelectedCustomer();
  return useMutation<Customer, Error | ApiError, UpdateCustomerMutationOptions>({
    mutationKey: ['useUpdateCustomerMutation'],
    mutationFn: async ({ id, customer }: UpdateCustomerMutationOptions) =>
      await axiosInstance
        .put<Customer>(CUSTOMERS_API_URLS.SINGLE.replace(':id', `${id}`), { ...customer, name: customer.name.trim() })
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_CUSTOMER_LIST_QUERY_KEY, USE_FETCH_CUSTOMER_QUERY_KEY]
      });
      refetchCustomers();
    }
  });
};

export const useDeleteCustomerMutation = () => {
  const queryClient = useQueryClient();
  const { refetchCustomers } = useSelectedCustomer();

  return useMutation({
    mutationKey: ['useDeleteCustomer'],
    mutationFn: async (id: number) =>
      await axiosInstance
        .delete(CUSTOMERS_API_URLS.SINGLE.replace(':id', `${id}`))
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_CUSTOMER_LIST_QUERY_KEY]
      });
      refetchCustomers();
    }
  });
};
