import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';

import { ConnectionError, PageLoadingIndicator, PageWrapper } from 'app/components';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  USE_FETCH_DASHBOARD_CONTROLS_QUERY_KEY,
  useFetchDashboardControls
} from 'app/queries/useDashboardControlsQueries';
import { CommonFilters } from 'app/types';
import {
  buildFiltersFromSearchParams,
  filterDashboardControls,
  updateSearchParamsWithFilter
} from 'app/utils/control-utils';

import { ActionCards } from './ActionCards/ActionCards';
import { ControlsReport } from './ControlsReport/ControlsReport';
import { ProgressReport } from './ProgressReport/ProgressReport';
import { SolutionCards } from './SolutionCards/SolutionCards';
import { FilterPanel } from './FilterPanel';

export const Dashboard: FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();

  // allows us to delay the query until we are ready (i.e. we have any filters from searchparams)
  const [ready, setReady] = useState(false);
  // get any filters from urlsearchparams
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<CommonFilters>({});

  const { data, isFetching, error } = useFetchDashboardControls(ready);

  const handleFilterChange = (prop: FILTER_PROP_NAMES, value?: string | string[]) => {
    // this will also trigger the useEffect below which calls setFilters
    setSearchParams(updateSearchParamsWithFilter(searchParams, prop, value));
  };

  const filteredData = useMemo(() => {
    if (isFetching) return data || [];
    return filterDashboardControls(data || [], filters);
  }, [data, filters, isFetching]);

  useEffect(() => {
    setFilters(buildFiltersFromSearchParams(searchParams));

    setReady(true);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [USE_FETCH_DASHBOARD_CONTROLS_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  return (
    <PageWrapper>
      <Flex gap='4' direction='column' height='fit-content' width='100%'>
        {isFetching && <PageLoadingIndicator />}
        {error && (
          <div data-testid='dashboard-error'>
            <ConnectionError />
          </div>
        )}
        {ready && !isFetching && data && (
          <>
            <ActionCards dashboardControls={data} />
            <ProgressReport dashboardControls={data} />
            <Flex gap='4' height='fit-content' width='100%'>
              <FilterPanel filters={filters} onFilterChange={handleFilterChange} />
              <Flex gap='4' direction='column' height='fit-content' width='100%'>
                <ControlsReport dashboardControls={filteredData} totalControls={data.length} />
                <SolutionCards dashboardControls={filteredData} />
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </PageWrapper>
  );
};
