import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Warning } from '@phosphor-icons/react';
import { Callout, Flex, Spinner, TextArea } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { PrimaryButton, SecondaryButton } from 'app/components/Buttons';
import { INITIAL_FINDINGS_REQUIRED_ROLES } from 'app/constants/rbac';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useInitialFindingsMutation } from 'app/queries/useControlQueries';
import { CustomerControl } from 'app/types/controls';

import { SidePanelSection } from './SidePanelSection';

const useStyles = tss.withName('InitialFindingsSection').create(() => ({
  readonly: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.7,
    fontWeight: 400,
    padding: '0 8px',
    whiteSpace: 'pre-wrap'
  },
  isAllowedEdit: {
    cursor: 'text',

    '&:hover, &:focus': {
      backgroundColor: 'var(--bg-default)'
    }
  },
  placeholder: {
    color: 'var(--font-muted-color)',
    fontStyle: 'italic'
  },
  textarea: {
    minHeight: '74px',
    boxShadow: 'inset 0 0 0 var(--text-area-border-width) var(--slate-200)',
    backgroundColor: 'var(--bg-inputs)',

    '&>textarea': {
      padding: '8px 12px'
    }
  }
}));

type InitialFindingsSectionProps = {
  control: CustomerControl;
  onControlChange: (control: CustomerControl) => void;
};

export const InitialFindingsSection: FunctionComponent<InitialFindingsSectionProps> = ({
  control,
  onControlChange
}) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [newValue, setNewValue] = useState(control.initialFindings);
  const [editMode, setEditMode] = useState(false);

  const { mutate, isPending, isError, reset } = useInitialFindingsMutation();

  const userIsAllowed = useMemo(() => isAllowed(INITIAL_FINDINGS_REQUIRED_ROLES), [isAllowed]);

  const handleClick = () => {
    if (userIsAllowed) {
      setEditMode(true);
    }
  };

  const handleCancel = () => {
    reset();
    setNewValue(control.initialFindings);
    setEditMode(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      { externalControlId: control.externalControlId, initialFindings: newValue || '' },
      {
        onSuccess: response => {
          // just update the fields we care about
          control.initialFindings = response.initialFindings;
          onControlChange(response);
          setEditMode(false);
        }
      }
    );
  };

  useEffect(() => setNewValue(control.initialFindings), [control.initialFindings]);

  return (
    <SidePanelSection label='Initial findings' onToggle={oldState => oldState && handleCancel()}>
      {!editMode && (
        <div
          className={clsx(classes.readonly, userIsAllowed && classes.isAllowedEdit)}
          onClick={handleClick}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleClick();
            }
          }}
          data-testid='readonly-view'
          tabIndex={0}
        >
          {!newValue && (
            <span className={classes.placeholder}>
              No initial findings.
              <br />
              Click here to start typing.
            </span>
          )}
          {newValue && <span>{newValue}</span>}
        </div>
      )}
      {editMode && userIsAllowed && (
        <form onSubmit={handleSubmit} data-testid='editmode-view'>
          <Flex gap='2' direction='column'>
            <TextArea
              className={classes.textarea}
              resize='vertical'
              color='gray'
              autoFocus
              placeholder='No initial findings.'
              disabled={isPending}
              value={newValue}
              onChange={e => setNewValue(e.target.value)}
              data-testid='initialFindings-input'
            />
            {isError && (
              <Callout.Root color='red' size='1' data-testid='initialFindings-error'>
                <Callout.Icon>
                  <Warning />
                </Callout.Icon>
                <Callout.Text>Unable to save data.</Callout.Text>
              </Callout.Root>
            )}
            <Flex gap='4' direction='row' justify='end' align='center'>
              {isPending && <Spinner />}
              <SecondaryButton
                type='reset'
                onClick={handleCancel}
                disabled={isPending}
                data-testid='cancel-btn'
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton type='submit' disabled={isPending} data-testid='submit-btn'>
                Save
              </PrimaryButton>
            </Flex>
          </Flex>
        </form>
      )}
    </SidePanelSection>
  );
};
