import { FunctionComponent, useEffect } from 'react';
import { matchPath, Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import { NotFoundPage, UnauthorisedPage } from 'app/components';
import {
  CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES,
  CUSTOMER_MANAGEMENT_REQUIRED_ROLES,
  MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES,
  USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES,
  USER_MANAGEMENT_REQUIRED_ROLES
} from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';

import { CreateCustomer, Customers, UpdateCustomer } from './AdminCentre/Customers';
import {
  CreateMasterControl,
  CreateMasterControlBulletin,
  MasterControls,
  UpdateMasterControl
} from './AdminCentre/MasterControls';
import { CreateUser, UpdateUser, Users } from './AdminCentre/Users';
import { Controls } from './Controls';
import { Dashboard } from './Dashboard';

const defaultDocumentTitle = 'Overwatch';

const ROUTES = [
  {
    path: URLS.DASHBOARDS,
    element: <Dashboard />,
    title: 'Overwatch - Dashboards'
  },
  {
    path: URLS.CONTROLS,
    element: <Controls />,
    title: 'Overwatch - Controls'
  },
  {
    path: URLS.ADMIN_CENTRE_CUSTOMERS,
    element: <Customers />,
    title: 'Overwatch - Customer Management - Customers',
    restrictions: CUSTOMER_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CUSTOMERS_CREATE,
    element: <CreateCustomer />,
    title: 'Overwatch - Customer Management - New Customer',
    restrictions: CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CUSTOMERS_EDIT,
    element: <UpdateCustomer />,
    title: 'Overwatch - Customer Management - Edit Customer',
    restrictions: CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_USERS,
    element: <Users />,
    title: 'Overwatch - User Management - Users',
    restrictions: USER_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_USERS_CREATE,
    element: <CreateUser />,
    title: 'Overwatch - User Management - New User',
    restrictions: USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_USERS_EDIT,
    element: <UpdateUser />,
    title: 'Overwatch - User Management - Edit User',
    restrictions: USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CONTROLS,
    element: <MasterControls />,
    title: 'Overwatch - Control Management - Master Controls',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CONTROLS_CREATE,
    element: <CreateMasterControl />,
    title: 'Overwatch - Control Management - New Control',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CONTROLS_EDIT,
    element: <UpdateMasterControl />,
    title: 'Overwatch - Control Management - Edit Control',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CONTROLS_BULLETIN_CREATE,
    element: <CreateMasterControlBulletin />,
    title: 'Overwatch - Control Management - New Bulletin',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CONTROLS_BULLETIN_EDIT,
    element: <CreateMasterControlBulletin />,
    title: 'Overwatch - Control Management - New Bulletin',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
];

const Routes: FunctionComponent = () => {
  const location = useLocation();
  const { isAllowed } = useLoggedInUser();
  useEffect(() => {
    const currentRoute = ROUTES.find(route => matchPath(route.path, location.pathname));

    document.title = currentRoute ? currentRoute.title : defaultDocumentTitle;
  }, [location]);

  return (
    <ReactRoutes>
      <Route path='/'>
        {ROUTES.map(route => {
          return !route.restrictions || isAllowed(route.restrictions) ? (
            <Route key={route.path} path={route.path} element={route.element} />
          ) : (
            <Route key={route.path} path={route.path} element={<UnauthorisedPage />} />
          );
        })}
        <Route index element={<Navigate to={URLS.DASHBOARDS} replace={true} />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routes;
