import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';
import { Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { ConnectionError, FreeTextFilter, PrimaryButton } from 'app/components';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers';
import { Pagination } from 'app/components/Tables';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { SortBy, SortOrder } from 'app/constants/controls';
import { MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES } from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useFetchMasterControlsList } from 'app/queries/useMasterControlsQueries';
import { MasterControl } from 'app/types';

import { MasterControlsTable } from './MasterControlsTable/MasterControlsTable';

const useStyles = tss.withName('MasterControls').create(() => ({
  container: {
    gap: '16px'
  }
}));

export const MasterControls: FunctionComponent = () => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [freeText, setFreeText] = useState('');
  const [sortState, setSortState] = useState({
    sortBy: (searchParams.get('sort')?.split(',')[0] as SortBy) || 'name',
    sortOrder: (searchParams.get('sort')?.split(',')[1] as SortOrder) || 'desc'
  });

  const { data, isFetching, isLoading, isError } = useFetchMasterControlsList({
    enabled: true,
    page: currentPage,
    size: pageSize,
    sort: `${sortState.sortBy},${sortState.sortOrder}`,
    freeText
  });

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const handleFreeTextChange = (text: string) => {
    setFreeText(text);
    setCurrentPage(0);
  };

  const [pageObject, setPageObject] = useState(
    data?.page || { totalPages: 0, totalElements: 0, size: 0, number: 0 }
  );

  const hasCreateAccess = useMemo(
    () => isAllowed(MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES),
    [isAllowed]
  );

  useEffect(
    () => setPageObject(prevRowCount => (data?.page !== undefined ? data?.page : prevRowCount)),
    [data, data?.page, setPageObject]
  );

  useEffect(() => {
    setSearchParams(prevParams => {
      prevParams.set('sort', `${sortState.sortBy},${sortState.sortOrder}`);
      return prevParams;
    });
  }, [setSearchParams, sortState]);

  return (
    <AdminCentrePageWrapper
      section={ADMIN_SECTIONS.CONTROLS}
      toolbar={
        <Flex gap='4' wrap='wrap' flexGrow='1' justify='between'>
          {hasCreateAccess ? (
            <Link to={`/${URLS.ADMIN_CENTRE_CONTROLS_CREATE}`}>
              <PrimaryButton>
                <Plus size={16} />
                Add control
              </PrimaryButton>
            </Link>
          ) : (
            <div />
          )}

          <FreeTextFilter defaultValue={freeText} onChange={handleFreeTextChange} />
        </Flex>
      }
    >
      <Flex direction='column' className={classes.container}>
        {isError && <ConnectionError />}
        <MasterControlsTable
          data={(data?.content || []) as MasterControl[]}
          pageSize={pageSize}
          sortState={sortState}
          setSortState={setSortState}
          isLoading={isFetching || isLoading}
        />
        <Pagination
          currentPage={currentPage}
          pageObject={pageObject}
          pageSize={pageSize}
          perPageItems={(data?.content.length || 0) + pageSize * currentPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          isDisabled={isFetching || isLoading}
        />
      </Flex>
    </AdminCentrePageWrapper>
  );
};
