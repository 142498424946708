import { FunctionComponent } from 'react';
import { Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

import {
  FreeTextFilter,
  ImpactFilter,
  InitialFindingsFilter,
  LicensedFilter,
  NISPrinciplesFilter,
  RiskFilter,
  SolutionsFilter,
  StatusFilter
} from 'app/components/Filters';
import { NAVBAR_HEIGHT } from 'app/constants/app';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { CommonFilters } from 'app/types';

import { SortSelect } from './SortSelect';

const useStyles = tss.withName('FilterPanel').create(() => ({
  container: {
    background: 'var(--white)',
    padding: '8px 8px 16px',
    borderRadius: 'var(--radius-4)',

    overflow: 'auto',
    // height is: height of screen - navbar height - padding from top and bottom of page wrapper
    maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px - 32px)`,

    '& > *': {
      borderBottom: '1px solid var(--slate-200)',
      padding: '0 0 16px'
    },
    '& > *:last-child': {
      borderBottom: 'none',
      padding: '0 8px'
    }
  },
  filterContainer: {
    '& > *': {
      borderBottom: '1px solid var(--slate-200)',
      padding: '16px 0'
    },
    '& > *:last-child': {
      borderBottom: 'none',
      padding: '16px 0 0'
    }
  },
  freetextFilter: {
    backgroundColor: 'var(--bg-inputs)'
  }
}));

type FilterPanelProps = {
  filters: CommonFilters;
  sort: string;
  onFilterChange: (prop: FILTER_PROP_NAMES, value?: string | string[]) => void;
  onSortChange: (newSort: string) => void;
};

export const FilterPanel: FunctionComponent<FilterPanelProps> = ({
  filters,
  onFilterChange,
  sort,
  onSortChange
}) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' className={classes.container} data-testid='filters-panel'>
      <Flex direction='column' gap='2'>
        <FreeTextFilter
          defaultValue={filters.freeText}
          className={classes.freetextFilter}
          onChange={(value: string) => onFilterChange(FILTER_PROP_NAMES.FREETEXT, value)}
        />
        <SortSelect defaultValue={sort} onChange={onSortChange} />
      </Flex>
      <Flex direction='column' className={classes.filterContainer}>
        <StatusFilter
          defaultValue={filters.statuses}
          defaultAsOpen={!!filters.statuses}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.STATUSES, value)}
        />
        <LicensedFilter
          defaultValue={filters.licensed}
          defaultAsOpen={!!filters.licensed}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.LICENSED, value)}
        />
        <RiskFilter
          defaultValue={filters.risk}
          defaultAsOpen={!!filters.risk}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.RISK, value)}
        />
        <ImpactFilter
          defaultValue={filters.impact}
          defaultAsOpen={!!filters.impact}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.IMPACT, value)}
        />
        <InitialFindingsFilter
          defaultValue={filters.initialFindings}
          defaultAsOpen={!!filters.initialFindings}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.INITIAL_FINDINGS, value)}
        />
        <SolutionsFilter
          defaultValue={filters.solutionIds}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.SOLUTIONS, value)}
        />
        <NISPrinciplesFilter
          defaultValue={filters.nisPrinciplesIds}
          onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.NIS_PRINCIPLES, value)}
        />
      </Flex>
    </Flex>
  );
};
