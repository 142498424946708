import { FunctionComponent } from 'react';

import { RiskTag } from './RiskTag';

type BusinessImpactTagProps = {
  value: string;
};

export const BusinessImpactTag: FunctionComponent<BusinessImpactTagProps> = ({ value }) => (
  <RiskTag value={value} label='Impact' />
);
