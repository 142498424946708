import { tss } from 'tss-react';

export const useCommonFilterStyles = tss.withName('CommonFilter').create(() => ({
  container: {
    gap: '8px'
  },
  checkboxItem: {
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'var(--bg-default)'
    },

    '& > *': {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '6px'
    }
  }
}));

export enum YES_NO_VALUE {
  YES = 'YES',
  NO = 'NO'
}

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: YES_NO_VALUE.YES },
  { label: 'No', value: YES_NO_VALUE.NO }
];

export enum FILTER_PROP_NAMES {
  FREETEXT = 'freeText',
  IMPACT = 'impact',
  INITIAL_FINDINGS = 'initialFindings',
  LICENSED = 'licensed',
  NIS_PRINCIPLES = 'nisPrinciplesIds',
  RISK = 'risk',
  SOLUTIONS = 'solutionIds',
  STATUSES = 'statuses'
}
