import { FunctionComponent } from 'react';
import { Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('NotFoundPage').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '48px'
  }
}));

export const NotFoundPage: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <Flex title='Page not found' data-testid='not-found' className={classes.container}>
      <Heading as='h1'>Not Found</Heading>
      <Text as='p'>The page you requested could not be found.</Text>
    </Flex>
  );
};
