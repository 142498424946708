import { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from 'react';

interface WhatsNewContextType {
  isWhatsNewOpen: boolean;
  setIsWhatsNewOpen: (isOpen: boolean) => void;
}

const WhatsNewContext = createContext<WhatsNewContextType>({
  isWhatsNewOpen: false,
  setIsWhatsNewOpen: () => {}
});

const WhatsNewProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isWhatsNewOpen, setIsWhatsNewOpen] = useState(false);

  return (
	<WhatsNewContext.Provider value={{ isWhatsNewOpen, setIsWhatsNewOpen }}>
	  {children}
	</WhatsNewContext.Provider>
  );
};

const useWhatsNewContext = () => useContext(WhatsNewContext);

export { useWhatsNewContext, WhatsNewProvider };
