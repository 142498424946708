export enum URLS {
  DASHBOARDS = 'dashboards',
  CONTROLS = 'controls',
  ADMIN_ROOT = 'admin-centre',
  ADMIN_CENTRE_USERS = 'admin-centre/users',
  ADMIN_CENTRE_USERS_CREATE = 'admin-centre/users/create',
  ADMIN_CENTRE_USERS_EDIT = 'admin-centre/users/:id/edit',
  ADMIN_CENTRE_CUSTOMERS = 'admin-centre/customers',
  ADMIN_CENTRE_CUSTOMERS_CREATE = 'admin-centre/customers/create',
  ADMIN_CENTRE_CUSTOMERS_EDIT = 'admin-centre/customers/:id/edit',
  ADMIN_CENTRE_CONTROLS = 'admin-centre/controls',
  ADMIN_CENTRE_CONTROLS_CREATE = 'admin-centre/controls/create',
  ADMIN_CENTRE_CONTROLS_EDIT = 'admin-centre/controls/:id/edit',
  ADMIN_CENTRE_CONTROLS_BULLETIN_CREATE = 'admin-centre/controls/create/bulletin',
  ADMIN_CENTRE_CONTROLS_BULLETIN_EDIT = 'admin-centre/controls/:id/bulletin'
}

export const CONTROLS_API_URLS = {
  ROOT: '/customer-controls',
  SINGLE: '/customer-controls/:controlId',
  NOTES: '/customer-controls/:controlId/notes',
  INITIAL_FINDINGS: '/customer-controls/:controlId/initial-findings',
  STATUS: '/customer-controls/:controlId/status',
  LICENCED: '/customer-controls/:controlId/licenced'
};

export const SOLUTIONS_API_URLS = {
  ROOT: '/solutions/all'
};

export const NIS_PRINCIPLES_API_URLS = {
  ROOT: '/nis-principles/all'
};

export const USERS_API_URLS = {
  ROOT: '/users',
  LOGGED_IN_USER: '/users/logged-in-user',
  SINGLE: '/users/:userId'
};

export const CUSTOMERS_API_URLS = {
  ROOT: '/customers',
  ALL: '/customers/all',
  SINGLE: '/customers/:id'
};

export const DASHBOARD_CONTROLS_API_ROOT = {
  ROOT: '/dashboard-controls'
};

export const MASTER_CONTROLS_API_URLS = {
  ROOT: '/master-controls',
  SINGLE: '/master-controls/:id'
};

export const WHATS_NEW_API_URLS = {
  ROOT: '/whats-new',
  CREATE: '/whats-new/bulletins',
  SINGLE: '/whats-new/bulletins/:id'
};
