import { FunctionComponent, useEffect, useState } from 'react';
import { CaretLeft, CaretLineLeft, CaretLineRight, CaretRight } from '@phosphor-icons/react';
import { Flex, IconButton, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PageObject } from 'app/types';

const useStyles = tss.withName('UsersPagination').create(() => ({
  paginationButton: {
    color: 'var(--font-primary-color)',
    background: 'transparent',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'var(--white)'
    }
  },
  paginationInput: {
    color: 'var(--font-primary-color)',
    backgroundColor: 'var(--white)',
    padding: '6px',
    border: '1px solid var(--slate-200)',
    borderRadius: '6px'
  },
  pageSizeSelect: {
    color: 'var(--font-primary-color)',
    backgroundColor: 'var(--white)',
    padding: '6px 8px',
    border: '1px solid var(--slate-200)',
    borderRadius: '6px'
  },
  pageSizeText: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.43
  },
  totalItems: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.43
  }
}));

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

type PaginationProps = {
  currentPage: number;
  pageObject: PageObject;
  pageSize: number;
  perPageItems: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  isDisabled?: boolean;
};

export const Pagination: FunctionComponent<PaginationProps> = ({
  currentPage,
  pageObject,
  pageSize,
  perPageItems,
  onPageChange,
  onPageSizeChange,
  isDisabled
}) => {
  const { classes } = useStyles();

  const [inputValue, setInputValue] = useState<string | number>(currentPage + 1);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === '') {
      setInputValue('');
    } else if (
      /^\d+$/.test(value) &&
      Number(value) >= 1 &&
      Number(value) <= pageObject.totalPages
    ) {
      setInputValue(Number(value));
      onPageChange(Number(value) - 1);
    }
  };

  useEffect(() => {
    setInputValue(currentPage + 1);
  }, [currentPage]);

  return (
    <Flex gap='2' align='center' justify='between'>
      <Text className={classes.totalItems} data-testid='total-items'>
        {pageObject.totalElements > 0 &&
          `Showing ${currentPage * pageSize + 1} - ${Math.min(perPageItems, pageObject.totalElements)} of ${pageObject.totalElements}`}
      </Text>
      {pageObject.totalPages > 1 && (
        <Flex gap='4' align='center' data-testid='table-pagination'>
          {currentPage !== 0 && (
            <>
              <IconButton
                variant='ghost'
                className={classes.paginationButton}
                onClick={() => onPageChange(0)}
                name='First page'
                data-testid='table-pagination-first-page'
                disabled={isDisabled}
				aria-label='First page'
              >
                <CaretLineLeft size={16} />
              </IconButton>

              <IconButton
                variant='ghost'
                className={classes.paginationButton}
                onClick={() => onPageChange(currentPage - 1)}
                name='Previous page'
                data-testid='table-pagination-previous-page'
                disabled={isDisabled}
				aria-label='Previous page'
              >
                <CaretLeft size={16} />
              </IconButton>
            </>
          )}
          <input
            type='number'
            min={1}
            max={pageObject.totalPages}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={() => setInputValue(currentPage + 1)}
            className={classes.paginationInput}
            data-testid='table-pagination-input'
            disabled={isDisabled}
			aria-label='Page number'
          />
          {currentPage < pageObject.totalPages - 1 && (
            <>
              <IconButton
                variant='ghost'
                className={classes.paginationButton}
                onClick={() => onPageChange(currentPage + 1)}
                name='Next page'
                data-testid='table-pagination-next-page'
                disabled={isDisabled}
				aria-label='Next page'
              >
                <CaretRight size={16} />
              </IconButton>

              <IconButton
                variant='ghost'
                className={classes.paginationButton}
                onClick={() => onPageChange(pageObject.totalPages - 1)}
                name='Last page'
                data-testid='table-pagination-last-page'
                disabled={isDisabled}
				aria-label='Last page'
              >
                <CaretLineRight size={16} />
              </IconButton>
            </>
          )}
        </Flex>
      )}

      <Flex gap={'2'} align={'center'}>
        <select
          value={pageSize}
          onChange={e => onPageSizeChange(Number(e.target.value))}
          className={classes.pageSizeSelect}
          data-testid='page-size-select'
          disabled={isDisabled}
        >
          {PAGE_SIZE_OPTIONS.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        <Text as='span' className={classes.pageSizeText}>
          per page
        </Text>
      </Flex>
    </Flex>
  );
};
