import { FunctionComponent, useEffect, useState } from 'react';
import { CheckboxGroup } from '@radix-ui/themes';

import { useCommonFilterStyles } from 'app/constants/filters';
import { CommonFilterProps } from 'app/types/filters';

import { FilterSection, FilterSectionProps } from './FilterSection';

type CheckboxFilterProps = FilterSectionProps &
  CommonFilterProps & {
    options: JSX.Element[];
  };

export const CheckboxFilter: FunctionComponent<CheckboxFilterProps> = ({
  // FilterSectionProps
  label,
  labelId,
  defaultAsOpen,
  // CommonFilterProps
  defaultValue,
  onChange,
  // CheckboxFilterProps
  options
}) => {
  const { classes } = useCommonFilterStyles();

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue || []);
  }, [defaultValue]);

  return (
    <FilterSection label={label} labelId={labelId} defaultAsOpen={defaultAsOpen}>
      <CheckboxGroup.Root
        className={classes.container}
        defaultValue={defaultValue}
        value={value}
        name={labelId}
        aria-labelledby={labelId}
        onValueChange={e => {
          setValue(e);
          onChange(e);
        }}
      >
        {options}
      </CheckboxGroup.Root>
    </FilterSection>
  );
};
