import { FunctionComponent } from 'react';

import { RiskTag } from './RiskTag';

type SecurityRiskTagProps = {
  value: string;
};

export const SecurityRiskTag: FunctionComponent<SecurityRiskTagProps> = ({ value }) => (
  <RiskTag value={value} label='Risk' />
);
