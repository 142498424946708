import { FunctionComponent, useMemo } from 'react';
import { Badge } from '@radix-ui/themes';
import { tss } from 'tss-react';

const getColors = (color?: string) => {
  switch (color) {
    case 'red':
      return {
        backgroundColor: 'var(--risk-red-bg)',
        color: 'var(--risk-red-font)'
      };
    case 'amber':
      return {
        backgroundColor: 'var(--risk-amber-bg)',
        color: 'var(--risk-amber-font)'
      };
    case 'green':
      return {
        backgroundColor: 'var(--risk-green-bg)',
        color: 'var(--risk-green-font)'
      };
    default:
      return {};
  }
};

const useStyles = tss
  .withName('RiskTag')
  .withParams<{ color?: string }>()
  .create(({ color }) => ({
    container: {
      padding: 'var(--space-1)',
      fontSize: '12px',
      fontWeight: 900,
      lineHeight: '12px',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',

      '&:where(.rt-variant-soft)': getColors(color)
    }
  }));

type RiskTagProps = {
  value: string;
  label?: string;
};

export const RiskTag: FunctionComponent<RiskTagProps> = ({ value, label }) => {
  const color = useMemo(() => {
    if (value.toLocaleLowerCase() === 'high') {
      return 'red';
    } else if (value.toLocaleLowerCase() === 'medium') {
      return 'amber';
    } else if (value.toLocaleLowerCase() === 'low') {
      return 'green';
    }
    return undefined;
  }, [value]);

  const { classes } = useStyles({ color });

  return (
    <Badge size='3' color={color} className={classes.container}>
      {value} {label}
    </Badge>
  );
};
