import { FunctionComponent, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CaretDown, MagnifyingGlass } from '@phosphor-icons/react';
import { Button, Popover, Text, TextField } from '@radix-ui/themes';
import clsx from 'clsx';
import { Command as CommandPrimitive } from 'cmdk';
import { tss } from 'tss-react';

import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import { SimpleCustomer } from 'app/types';

import { SwitchCustomerItem } from './SwitchCustomerItem';

const useStyles = tss.withName('SwitchCustomer').create(() => ({
  button: {
    boxShadow: 'none',
    border: '1px solid var(--slate-200)',
    backgroundColor: 'var(--bg-inputs)',
    cursor: 'pointer',
    color: 'var(--font-primary-color)',
    padding: '8px 12px',
    fontSize: '14px',
    lineHeight: 1.5,
    fontWeight: 400,
    height: '40px',
    width: '200px',
    justifyContent: 'space-between',
    gap: '10px',

    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },

    '&:hover': {
      backgroundColor: 'var(--gray-a3)'
    }
  },
  popoverContent: {
    width: '200px',
    padding: 0,
    background: 'transparent',
    overflow: 'visible',
    boxShadow: 'none'
  },
  command: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  input: {
    backgroundColor: 'var(--white)',
    height: '40px',
    boxShadow: 'var(--shadow-5)'
  },
  list: {
    maxHeight: '270px',
    overflow: 'auto',
    backgroundColor: 'var(--white)',
    padding: '4px',
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    borderTop: 'none',
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    boxShadow: 'var(--shadow-5)'
  },
  item: {
    borderRadius: '6px',
    padding: '6px 8px',
    display: 'flex',
    gap: '8px',
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 400,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover, &[data-selected="true"]': {
      backgroundColor: 'var(--gray-a3)'
    },
    '&[data-selected="true"]': {
      outline: '2px solid var(--focus-8)',

      '&:hover': {
        outline: 'none'
      }
    },
    '& > svg': {
      opacity: 0
    }
  },
  selected: {
    backgroundColor: 'var(--bg-default)',

    '& > svg': {
      opacity: 1
    }
  },
  groupHeading: {
    padding: '6px 8px',
    display: 'flex',
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 700,
    borderTop: '1px solid var(--slate-200)'
  },
  empty: {
    padding: '6px 8px',
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 400
  }
}));

export const SwitchCustomer: FunctionComponent = () => {
  const { classes } = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);

  const { isLoading, customers, selectedCustomer, setSelectedCustomer } = useSelectedCustomer();

  const onSelectCustomer = (customer: SimpleCustomer) => {
    setSelectedCustomer(customer);
    setOpen(false);

    searchParams.delete('controlId');
    setSearchParams(searchParams);
  };

  const [active, inactive] = useMemo(
    () => [
      (customers || []).filter(val => val.enabled).sort((a, b) => a.name.localeCompare(b.name)),
      (customers || []).filter(val => !val.enabled).sort((a, b) => a.name.localeCompare(b.name))
    ],
    [customers]
  );

  // if the customers haven't been loaded yet, don't render anything
  return isLoading ? (
    <></>
  ) : (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <Button
          variant='outline'
          color='gray'
          role='combobox'
          aria-expanded={open}
          aria-label='Switch Customer dropdown'
          className={classes.button}
          data-testid='switch-customer-btn'
        >
          <span>{selectedCustomer ? selectedCustomer.name : 'Select customer'}</span>
          <CaretDown />
        </Button>
      </Popover.Trigger>
      <Popover.Content className={classes.popoverContent} data-testid='switch-customer-content'>
        <CommandPrimitive
          className={classes.command}
          filter={(value, search) =>
            value.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ? 1 : 0
          }
        >
          <CommandPrimitive.Input className={classes.input} asChild placeholder='Search...'>
            <TextField.Root>
              <TextField.Slot>
                <MagnifyingGlass size={16} />
              </TextField.Slot>
            </TextField.Root>
          </CommandPrimitive.Input>
          <CommandPrimitive.List className={classes.list}>
            <CommandPrimitive.Empty className={classes.empty}>
              No customers found.
            </CommandPrimitive.Empty>
            <CommandPrimitive.Group>
              {active.map(customer => (
                <SwitchCustomerItem
                  key={customer.id}
                  customer={customer}
                  classNames={clsx(
                    classes.item,
                    selectedCustomer?.id === customer.id && classes.selected
                  )}
                  onSelect={() => onSelectCustomer(customer)}
                />
              ))}
            </CommandPrimitive.Group>
            {inactive.length > 0 && (
              <CommandPrimitive.Group
                heading={<Text className={classes.groupHeading}>Inactive</Text>}
              >
                {inactive.map(customer => (
                  <SwitchCustomerItem
                    key={customer.id}
                    customer={customer}
                    classNames={clsx(
                      classes.item,
                      selectedCustomer?.id === customer.id && classes.selected
                    )}
                    onSelect={() => {
                      setSelectedCustomer(customer);
                      setOpen(false);
                    }}
                  />
                ))}
              </CommandPrimitive.Group>
            )}
          </CommandPrimitive.List>
        </CommandPrimitive>
      </Popover.Content>
    </Popover.Root>
  );
};
