import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from '@phosphor-icons/react';
import { Flex, IconButton, Text } from '@radix-ui/themes';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { format } from 'date-fns';
import { tss } from 'tss-react';

import {
  BusinessImpactTag,
  PageLoadingIndicator,
  SecurityRiskTag,
  SolutionIcon
} from 'app/components';
import { ColumnSortButton } from 'app/components/Tables';
import { SortBy, SortOrder } from 'app/constants/controls';
import { LICENCES_SHORT_LABELS } from 'app/constants/customers';
import { MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES } from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { MasterControl } from 'app/types';

import { MasterControlDelete } from './MasterControlDelete';

const useStyles = tss
  .withName('MasterControlsTable')
  .withNestedSelectors<'showOnHover'>()
  .create(({ classes }) => ({
    table: {
      width: '100%',
      border: '1px solid var(--slate-200)',
      borderRadius: '6px',
      padding: '16px 8px',
      gap: '8px',
      backgroundColor: 'var(--white)',
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
      borderSpacing: '0'
    },
    rowText: {
      color: 'var(--font-primary-color)',
      fontSize: '14px',
      lineHeight: 1.71,
      display: 'block'
    },
    rowTextNoWrap: {
      overflow: 'hidden',
      textWrap: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '200px'
    },
    columnLabel: {
      color: 'var(--font-primary-color)',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5
    },
    row: {
      '&:hover, &:focus-within': {
        backgroundColor: 'var(--bg-default)'
      },
      [`&:hover .${classes.showOnHover}, &:focus .${classes.showOnHover}, &:focus-within .${classes.showOnHover}`]:
        {
          opacity: 1
        },
      '& > td': {
        borderTop: '1px solid var(--slate-200)',
        padding: '4px 8px 8px'
      }
    },
    rowHeader: {
      '& > th': {
        padding: '0 8px 8px'
      }
    },
    cell: {
      textAlign: 'left',
      verticalAlign: 'baseline'
    },
    showOnHover: {
      opacity: 0,
      '&:hover, &:focus': {
        opacity: 1
      }
    },
    iconButton: {
      margin: 0,
      color: 'var(--font-primary-color)',
      cursor: 'pointer'
    },
    deleteIconButton: {
      color: 'var(--red-a5)'
    }
  }));

type MasterControlsTableProps = {
  data: MasterControl[];
  pageSize: number;
  sortState: { sortBy: SortBy; sortOrder: SortOrder };
  setSortState: React.Dispatch<React.SetStateAction<{ sortBy: SortBy; sortOrder: SortOrder }>>;
  isLoading: boolean;
};

export const MasterControlsTable = ({
  data,
  pageSize,
  sortState,
  setSortState,
  isLoading
}: MasterControlsTableProps) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteRow, setDeleteRow] = useState<string | null>(null);

  const isAllowedCRUD = useMemo(
    () => isAllowed(MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES),
    [isAllowed]
  );

  const handleSortChange = useMemo(
    () => (column: SortBy) => {
      setSortState(prev => {
        const newSortOrder = prev.sortBy === column && prev.sortOrder === 'asc' ? 'desc' : 'asc';
        return { sortBy: column, sortOrder: newSortOrder };
      });
      setCurrentPage(0);
    },
    [setSortState]
  );

  const defaultColumns = useMemo<ColumnDef<MasterControl>[]>(
    () => [
      {
        accessorKey: 'externalControlId',
        header: () => (
          <ColumnSortButton
            label='Control ID'
            sortState={sortState}
            handleSort={() => handleSortChange('externalControlId')}
            value={'externalControlId'}
          />
        ),
        cell: ({ row }) => (
          <Text as='span' className={classes.rowText}>
            {row.original.externalControlId}
          </Text>
        )
      },
      {
        accessorKey: 'name',
        header: () => (
          <ColumnSortButton
            label='Name'
            sortState={sortState}
            handleSort={() => handleSortChange('name')}
            value={'name'}
          />
        ),
        cell: ({ row }) => (
          <Text as='span' className={classes.rowText}>
            {row.original.name}
          </Text>
        )
      },
      {
        accessorKey: 'm365',
        header: () => <Text className={classes.columnLabel}>M365</Text>,
        cell: ({ row }) => (
          <Text as='span' className={classes.rowText}>
            {row.original.m365Licences
              .map(m365Licence => LICENCES_SHORT_LABELS[m365Licence])
              .join(', ')}
          </Text>
        )
      },
      {
        accessorKey: 'solution',
        header: () => (
          <ColumnSortButton
            label='Solution'
            sortState={sortState}
            handleSort={() => handleSortChange('solution')}
            value={'solution.name'}
          />
        ),
        cell: ({ row }) =>
          row.original.solution && (
            <Flex gap={'2'}>
              <SolutionIcon solution={row.original.solution} />
              <Text as='span' className={clsx(classes.rowText, classes.rowTextNoWrap)}>
                {row.original.solution.name}
              </Text>
            </Flex>
          )
      },
      {
        accessorKey: 'securityRisk',

        header: () => (
          <ColumnSortButton
            label='Risk'
            sortState={sortState}
            handleSort={() => handleSortChange('securityRisk')}
            value={'securityRisk'}
          />
        ),
        cell: ({ row }) =>
          row.original.securityRisk && <SecurityRiskTag value={row.original.securityRisk} />
      },
      {
        accessorKey: 'businessRisk',
        header: () => (
          <ColumnSortButton
            label='Impact'
            sortState={sortState}
            handleSort={() => handleSortChange('businessRisk')}
            value={'businessRisk'}
          />
        ),
        cell: ({ row }) =>
          row.original.businessRisk && <BusinessImpactTag value={row.original.businessRisk} />
      },
      {
        accessorKey: 'nisPrinciples',
        header: () => (
          <ColumnSortButton
            label='NIS Principles'
            sortState={sortState}
            handleSort={() => handleSortChange('nisPrinciples')}
            value={'nisPrinciples'}
          />
        ),
        cell: ({ row }) => (
          <Flex direction={'column'}>
            {row.original.nisPrinciples?.map(({ name }) => (
              <Text as='span' className={clsx(classes.rowText, classes.rowTextNoWrap)} key={name}>
                {name}
              </Text>
            ))}
          </Flex>
        )
      },
      {
        accessorKey: 'lastUpdatedAt',
        header: () => <Text className={classes.columnLabel}>Updated</Text>,
        cell: ({ row }) => (
          <Text as='span' className={clsx(classes.rowText, classes.rowTextNoWrap)}>
            {format(row.original.lastUpdatedAt, 'yyyy-MM-dd')}
          </Text>
        )
      },
      {
        id: 'action',
        header: () => <Text className={classes.columnLabel}>Action</Text>,
        cell: ({ row }) => (
          <>
            {isAllowedCRUD && (
              <Flex gap='2'>
                <Link
                  title='Edit Control'
                  to={`/${URLS.ADMIN_CENTRE_CONTROLS_EDIT.replace(':id', `${row.original.externalControlId}`)}`}
                >
                  <IconButton
                    variant='ghost'
                    color='gray'
                    className={classes.iconButton}
                    aria-label='Edit Control'
                    title='Edit Control'
                  >
                    <Pencil size={20} weight='fill' aria-hidden='true' />
                  </IconButton>
                </Link>
                <IconButton
                  className={clsx(
                    classes.iconButton,
                    classes.deleteIconButton,
                    classes.showOnHover
                  )}
                  variant='ghost'
                  color='gray'
                  data-testid={`delete-control-${row.id}`}
                  onClick={() => setDeleteRow(row.id)}
                  title='Delete Control'
                  aria-label='Delete Control'
                >
                  <Trash size={20} weight='fill' aria-hidden='true' />
                </IconButton>
              </Flex>
            )}
          </>
        )
      }
    ],
    [sortState, handleSortChange, classes, isAllowedCRUD]
  );

  const table = useReactTable({
    data: data,
    columns: defaultColumns,
    state: {
      pagination: { pageIndex: currentPage, pageSize }
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true
  });

  return (
    <table className={classes.table}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id} className={classes.rowHeader}>
            {headerGroup.headers.map(header => (
              <th key={header.id} className={classes.cell}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {isLoading ? (
        <tbody>
          <tr>
            <td colSpan={table.getAllColumns().length}>
              <PageLoadingIndicator />
            </td>
          </tr>
        </tbody>
      ) : table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map(row => (
          <tbody key={row.id}>
            <tr className={classes.row}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className={classes.cell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {deleteRow === row.id && (
              <tr>
                <td colSpan={table.getAllColumns().length}>
                  <MasterControlDelete
                    externalControlId={row.original.externalControlId}
                    setDeleteRow={() => setDeleteRow(null)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        ))
      ) : (
        <tbody>
          <tr className={classes.row}>
            <Text className={classes.rowText}>No controls found</Text>
          </tr>
        </tbody>
      )}
    </table>
  );
};
