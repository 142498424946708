import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { NIS_PRINCIPLES_API_URLS } from 'app/constants/routes';
import { NISPrinciple } from 'app/types';

export const useFetchNISPrinciples = (enabled = false) =>
  useQuery({
    queryKey: ['useFetchNISPrinciples'],
    queryFn: () =>
      axiosInstance
        .get<NISPrinciple[]>(NIS_PRINCIPLES_API_URLS.ROOT, {})
        .then(response => response.data),
    enabled
  });
