import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Card, Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import {
  BusinessImpactTag,
  EmptyResult,
  PageLoadingIndicator,
  SecurityRiskTag
} from 'app/components';
import { useFetchCustomerControl } from 'app/queries/useControlQueries';
import { CustomerControl } from 'app/types';

import { SidePanelTabs } from './SidePanelTabs';

const useStyles = tss.withName('ControlSidePanel').create(() => ({
  container: {
    background: 'var(--white)',
    borderColor: 'var(--slate-200)',
    padding: '16px',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)',
    width: '100%'
  },
  innerContainer: {
    overflow: 'auto',
    height: '100%'
  },
  info: {
    paddingBottom: '16px',
    borderBottom: '1px solid var(--slate-200)'
  },
  id: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '20px',
    lineHeight: 1.4,
    fontWeight: 700
  },
  controlNote: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    fontWeight: 400
  }
}));

type ControlSidePanelProps = {
  externalControlId: number;
  onControlDetailRefresh: (control: CustomerControl) => void;
  onReturnFocusToList: (event: React.FocusEvent<HTMLDivElement, Element>) => void;
};

export const ControlSidePanel: FunctionComponent<ControlSidePanelProps> = ({
  externalControlId,
  onControlDetailRefresh,
  onReturnFocusToList
}) => {
  const { classes } = useStyles();
  const [controlData, setControlData] = useState<CustomerControl>();

  const ref = useRef<HTMLDivElement | null>(null);

  const { data, isFetching, error } = useFetchCustomerControl(true, externalControlId);

  const updateControlData = (updatedControl: CustomerControl) => {
    setControlData(updatedControl);
    onControlDetailRefresh(updatedControl);
  };

  useEffect(() => setControlData(data), [data]);

  // when the ref gains a value OR externalControlId changes, then focus on the element
  useEffect(() => ref.current?.focus(), [ref, externalControlId]);

  return (
    <Card
      className={classes.container}
      data-testid='customer-control-side-panel'
      tabIndex={-1}
      ref={ref}
      onBlur={onReturnFocusToList}
    >
      {isFetching && <PageLoadingIndicator />}
      {error && (
        <EmptyResult title='Control not found' content='Please select a control from the list.' />
      )}
      {controlData && (
        <Flex className={classes.innerContainer} direction='column' gap='2'>
          <Flex direction='column' gap='2' className={classes.info}>
            <Flex direction={'column'}>
              <Text as='span' className={classes.id}>
                #{controlData.externalControlId}
              </Text>
              <Heading as='h3' className={classes.heading}>
                {controlData.name}
              </Heading>
            </Flex>
            <Text as='p' className={classes.controlNote}>
              {controlData.controlNotes}
            </Text>
            <Flex gap='2' align='center'>
              {controlData.securityRisk && <SecurityRiskTag value={controlData.securityRisk} />}
              {controlData.businessRisk && <BusinessImpactTag value={controlData.businessRisk} />}
            </Flex>
          </Flex>
          <SidePanelTabs control={controlData} updateControl={updateControlData} />
        </Flex>
      )}
    </Card>
  );
};
